
  import { Component, Mixins, Prop, Emit, Watch, Ref } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { PropertyHouseSubTypes, PropertyAppartmentSubTypes, PropertyCommercialSubTypes, PropertyGroundSubTypes, PropertyParkingSubTypes } from "@/enums/property";
  import { HasProperyPart } from "@/mixins/has-property-part";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      ValidationProvider,
    },
  })
  export default class PropertyPartSubtypes extends Mixins(HasProperyPart) {
    @Prop({ default: null }) type!: "HOUSE" | "APPARTMENT" | "LAND" | "OFFICE" | "COMMERCIAL" | "INDUSTRIAL" | "PARKING" | null;

    @Prop({ default: null }) value!: PropertyHouseSubTypes | PropertyAppartmentSubTypes | PropertyCommercialSubTypes | PropertyGroundSubTypes | PropertyParkingSubTypes | null;

    localValue: PropertyHouseSubTypes | PropertyAppartmentSubTypes | PropertyCommercialSubTypes | PropertyGroundSubTypes | PropertyParkingSubTypes | null = null;

    get subtypes() {
      switch (this.type) {
        case "HOUSE":
          return PropertyHouseSubTypes;
          break;
        case "APPARTMENT":
          return PropertyAppartmentSubTypes;
          break;
        case "LAND":
          return PropertyGroundSubTypes;
          break;
        case "OFFICE":
          return [];
          break;
        case "COMMERCIAL":
          return PropertyCommercialSubTypes;
          break;
        case "INDUSTRIAL":
          return [];
          break;
        case "PARKING":
          return PropertyParkingSubTypes;
          break;
      }
      return [];
    }

    @Emit("input")
    handleInput(v: PropertyHouseSubTypes | PropertyAppartmentSubTypes | PropertyCommercialSubTypes | PropertyGroundSubTypes | PropertyParkingSubTypes | null) {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyHouseSubTypes | PropertyAppartmentSubTypes | PropertyCommercialSubTypes | PropertyGroundSubTypes | PropertyParkingSubTypes | null) {
      this.localValue = newValue;
    }
  }
